import '../../App.css';
import './style.css';
import { Button } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import { useNavigate } from "react-router-dom";
import GlobalStyle from '../../globalStyles';
import {Modal, Container, Header, Sidebar, Sidenav, Content,
  IconButton, Panel, FlexboxGrid, Drawer, Notification,useToaster} from 'rsuite';
import PauseIcon from '@rsuite/icons/legacy/Pause';
import PlusIcon from '@rsuite/icons/legacy/Plus';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  materialRenderers,
  materialCells,
} from '@jsonforms/material-renderers';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthedNavbar } from '../../components';

import { JsonForms } from '@jsonforms/react';

import React, { useState, useEffect } from 'react';

import { Table } from 'rsuite';
import { Menu } from '@mui/material';

const { Column, HeaderCell, Cell } = Table;

let baseURL = window.location.origin;

const darktheme = createTheme({
  palette: {
      type: 'dark', mode: 'dark'
  }
});

let participants = [
  "bank1",
  "bank1branch",
  "bank2",
  "bank2branch",
  "central"
]

/*let testRoles = {
  type: "array",
  items:{
    type : "object",
    properties: {
      payer: {type: "object",
        properties: {
          party: { type: "string", enum:[
            "nyic",
            "bankny",
            "bankofamerica",
            "hsbcny",
            "bankofnewyork",
            "wellsfargo",
            "usbank",
            "tdbank",
            "swift",
            "mastercard",
            "truist",
            "pncbank"
          ]},
          confirmed: { type:"string", enum:['waiting']}
        }
      }
    }
  }
}*/

/*const global= {
  options: {
    "defaultDateType": "datetime",
    "datePattern": "yyyy-MM-dd HH:mm:ss"
  },
}*/

/*
const testSchema = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "type": "object",
  "required": ["buyer", "seller", "underlyingAsset", "strikePrice", "expirationDate","optionType","status"],
  "properties": {
      "buyer": {
          "type": "object",
          "required": ["name","address","telephone"],
          "properties":{
              "name": {"type": "string"},
              "address": {"type": "string"},
              "telephone": {"type": "string"}
          }
      },
      "seller": {
          "type": "object",
          "required": ["name","address","telephone"],
          "properties":{
              "name": {"type": "string"},
              "address": {"type": "string"},
              "telephone": {"type": "string"}
          }
      },
      "underlyingAsset": {
          "type": "string"
      },
      "strikePrice": {
          "type": "number"
      },
      "expirationDate": {
          "type": "string",
          "format": "date-time"
      },
      "optionType": {
          "type": "string",
          "enum": ["call", "put"]
      },
      "status": {
          "type": "string",
          "enum": ["pending", "active", "expired", "executed"]
      }
  },
  "additionalProperties": false
}
*/

const Contracthubs = (props) => {

  const [tableWidth, setTableWidth] = useState(0)
  const [initializeWidth, setInitializeWidth] = useState(0)

  let autoResize = () => {
    setTableWidth(window.innerWidth-200);
    setInitializeWidth(Math.max(window.innerWidth-200-1400,150));
  }

  useEffect(() => {
      window.addEventListener('resize', autoResize)
      autoResize();
  }, [])

  const activityURL = "http://smartcontract.setlconnect.com:3005/events/activity";

  const [active, setActive] = React.useState('Contract Hosts');
  const [open, setOpen] = React.useState(false);
  const [overflow, setOverflow] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [hubDrawerOpen, setHubDrawerOpen] = React.useState(false);

  const [modalMessage, setModalMessage] = React.useState({})
  const [modalHeading, setModalHeading] = React.useState('Message')
  const [expand, setExpand] = React.useState(true);
  const [selectedContract, setSelectedContract] = React.useState(0)
  const [openWithHeader, setOpenWithHeader] = React.useState(false);

  const [availableContracts, setAvailableContracts] = React.useState([])
  const [contractHubs, setContractHubs] = React.useState([])
  const [newHubData, setNewHubData] = useState({});
  const [rolesData, setRolesData] = useState({});
  const [initData, setInitData] = useState({});

  const [rolesSchema, setRolesSchema] = useState({});
  const [dataSchema, setDataSchema] = useState({});

  const [successMessageText, setSuccessMessageText] = useState("success");
  const [failedMessageText, setFailedMessageText] = useState("failed");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  const handleHubDrawerOpen = () => setHubDrawerOpen(true);
  const handleHubDrawerClose = () => setHubDrawerOpen(false);

  const navigate = useNavigate();
  const toaster = useToaster();

  useEffect(() => {
    getContractHubs();
    getavailablecontracts();
  },[]);

  const successMessage = (messageText) => (
    <Notification type="success" header="New Contract Initiatized" closable style={{ width: 600 }}>
      <span style={{ width: 500 }}>
      Contract Id : <br />
      {messageText.contractId}<br />
      Hash : < br />
      {messageText.hash}
      </span>
      <hr />
    </Notification>
  );

  const failedMessage = (messageText) => (
    <Notification type="error" header="Initialization Failed" closable style={{ width: 600 }}>
      <span style={{ width: 500 }}>
      {messageText}
      </span>
      <hr />
    </Notification>
  );

  const Heading = ({selection}) => {
    return (
      <h4 style={GlobalStyle.pageTitleStyle}>{selection}</h4>
    )
  }

  const logout = () => {
    localStorage.removeItem('basicAuth');
    window.location.href = window.location.origin + process.env.PUBLIC_URL;
  }

  const ShowMessage = (contractHub, contractName,MessageHeading) => {
    //console.log('[MessageHeading]',MessageHeading)
    setModalMessage(contractName);
    setModalHeading(MessageHeading +' for '+contractName);
    let thisContract=0
    for (var i=0; i<availableContracts.length; i++) {
        if (availableContracts[i].contractHub === contractHub && availableContracts[i].contractName === contractName) {
            setSelectedContract(i)
            thisContract=i
        }
    }
    handleOpen();
  }

  
  const ShowHubDrawer = () => {
    handleHubDrawerOpen()
  }

  const ShowDrawer = (contractHub, contractName, MessageHeading) => {
    console.log('[MessageHeading]',MessageHeading)
    setModalMessage(contractName);
    setModalHeading(MessageHeading +' for '+contractName);
    let thisContract=0
    for (var i=0; i<availableContracts.length; i++) {
        if (availableContracts[i].contractHub === contractHub && availableContracts[i].contractName === contractName) {
            setSelectedContract(i)
            console.log('[selectedContract]',selectedContract)
            thisContract=i
        }
    }

    for (var key in availableContracts[thisContract]) {

      if (key=='schemas') {
        console.log('[found schemas]')
          for (var fkey in availableContracts[thisContract][key]){
              if (fkey=='genesisRoles'){
                console.log('[found genesisRoles]')
                setRolesSchema(availableContracts[thisContract][key][fkey])
              }
              if (fkey=='genesisData'){
                console.log('[found genesisData]')
                setDataSchema(availableContracts[thisContract][key][fkey])
              }
            }
        }
    }
    handleDrawerOpen();
  }

  const MessageModal = () => {
    let allcodestring='';
    console.log('[selectedContract]',selectedContract)

    if (availableContracts.length > 0) {
      for (var key in availableContracts[selectedContract]) {

          if (key=='functions') {
              for (var fkey in availableContracts[selectedContract][key]){
                  let codestring = fkey + " : "+availableContracts[selectedContract][key][fkey] ;
                  codestring = codestring.replace(/\t/g, '  ')
                  allcodestring = allcodestring + codestring + '\n\n'
              }
          }
      }

      return (
        <Modal size={'lg'} overflow={overflow} open={open} onClose={handleClose} enforceFocus={false}>
          <Modal.Header>
              <Modal.Title>{modalHeading}</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <SyntaxHighlighter language="javascript" style={docco}>
              {allcodestring}
          </SyntaxHighlighter>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      )
    } else {
      return null;
    }

  }

  const InitContract = async () => {
    try {
      // set up genesisRoles
      if (rolesData === undefined) {
        console.log('[rolesData is null]')
        throw 'Cannot initialize contract without initialization roles'
      }
      let genesisRoles=JSON.parse(JSON.stringify(rolesData));
      console.log('[rolesData]',rolesData);

      // set up genesisData
      if (initData === undefined) {
        console.log('[initData is null]')
        throw 'Cannot initialize contract without initialization data'
      }
      let genesisData=JSON.parse(JSON.stringify(initData));
      console.log('[genesisData]',genesisData);
      let params = {};
      params.roles=JSON.parse(JSON.stringify(genesisRoles));
      params.data=JSON.parse(JSON.stringify(genesisData));
      params.contractHub=availableContracts[selectedContract].contractHub;
      params.contractName=availableContracts[selectedContract].contractName;
      console.log(params);

      // call api to init contract
      let result = await contractInit(params);
      setSuccessMessageText(JSON.stringify(result));
      if (result.hasOwnProperty('failed')) {
        toaster.push(failedMessage(result.failed.error), "topCenter");
        return;
      } else {
        toaster.push(successMessage(result), "topCenter");
        setDrawerOpen(false)
      }
    } catch (error) {
        console.log(error);
        toaster.push(failedMessage(error), "topCenter");
    }
  }

  async function contractInit(params){
    const response = await fetch(baseURL+`/api/init`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify(params)
    })
    let result = await response.json();
    console.log(result);
    return result;
  }

  async function getavailablecontracts(){
    const response = await fetch(baseURL+`/api/getavailablecontracts`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    setAvailableContracts(results.availableContracts)
    return results;
  }

  async function getContractHubs(){
    const response = await fetch(baseURL+`/api/getcontracthubs`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
 
    setContractHubs(results.contractHubs.filter((contractHub) => contractHub.active))
    return results;
  }

  async function addContractHub(){

    handleHubDrawerClose();

    await fetch(baseURL+`/api/addcontracthub`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify({
        name: newHubData.name, 
        address: newHubData.address
      })
    }).then((contracts) => {
      setNewHubData({name:'', address:''});
      getContractHubs();
      getavailablecontracts()
    })
  }

  async function removeContractHub(name, address){
    await fetch(baseURL+`/api/removecontracthub`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify({
        address: address
      })
    }).then(() => {
      // remove record from contractHubs
      let newContractHubs = contractHubs.filter((contractHub) => contractHub.address !== address)
      setContractHubs(newContractHubs)

      // remove contracts from availableContracts
      let newAvailableContracts = availableContracts.filter((contract) => contract.contractHub !== name)
      setAvailableContracts(newAvailableContracts)
    });
  }

  const CompactCell = (props) => {
    return <Cell {...props} style={{ padding: 5 , paddingTop: 10}} />;
  };

  return (
    <div className="contractHubs">
      <div className="overlap-wrapper">
        <div className="overlap">
          <Container>

            <AuthedNavbar active={active} expand={expand} onSelect={setActive} expandCallBack={() => setExpand(!expand)} logoutCallBack={()=> logout()}/>
            <div className="frame-3">
            <div className="frame-5">
                  <div className="frame-6">
                    <div className="transactions-table">
                      <div className="frame-18">
                        <div className="text-wrapper-7">Contract Hosts</div>
                        <div className="frame-22">
                          <IconButton icon={<PlusIcon /> } placement="left" className="code-button" onClick={ShowHubDrawer}>
                            Add New Host
                          </IconButton>
                        </div>
                      </div>

                      <Table virtualized
                      height={500}
                      width={'100%'}
                      hover={false}
                      data={contractHubs}
                      >

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Host Name</HeaderCell>
                        <CompactCell dataKey="name" />
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Host Address</HeaderCell>
                        <CompactCell dataKey="address" />
                      </Column>

                      <Column 
                        flexGrow={1}
                        // fixed="right" 
                        fullText>
                        <HeaderCell>Action</HeaderCell>
                        <Cell style={{padding:5}}>
                          {rowData => (
                            <span>
                              <a onClick={() => removeContractHub(rowData.name, rowData.address)}> Remove Contract Host </a>
                            </span>
                          )}
                        </Cell>
                      </Column>

                    </Table>

                    </div>
                  </div>
                  <div className="mini-potfolio-card-2">
                    <div className="frame-18">
                      <div className="text-wrapper-7">Contracts</div>
                    </div>
                    <div className="frame-23">
                    <Table virtualized
                      height={500}
                      width={'100%'}
                      hover={false}
                      data={availableContracts}
                      onSortColumn={(sortColumn, sortType) => { console.log(sortColumn, sortType); }}
                      >

                      <Column
                        flexGrow={2}
                        fullText>
                        <HeaderCell>Contract Host</HeaderCell>
                        <CompactCell dataKey="contractHub" />
                      </Column>

                      <Column
                        flexGrow={2}
                        fullText>
                        <HeaderCell>Contract Name</HeaderCell>
                        <CompactCell dataKey="contractName" />
                      </Column>

                      <Column
                        flexGrow={5} 
                        fullText>
                        <HeaderCell>Contract Description</HeaderCell>
                        <Cell dataKey="description"/>
                      </Column>

                      <Column 
                        flexGrow={1}
                        // fixed="right" 
                        fullText>
                        <HeaderCell>Action</HeaderCell>
                        <Cell style={{padding:5}}>
                          {rowData => (
                            <span>
                            <a onClick={() => ShowMessage(rowData.contractHub, rowData.contractName,'Smart Contract Functions')}> View </a>
                            </span>
                          )}
                        </Cell>
                      </Column>

                      <Column 
                        flexGrow={1}
                        // fixed="right" 
                        fullText>
                        <HeaderCell>...</HeaderCell>
                        <Cell style={{padding:5}}>
                          {rowData => (
                            <span>
                            <a onClick={() => ShowDrawer(rowData.contractHub, rowData.contractName,'Smart Contract Functions')}> Initialize New Instance </a>
                            </span>
                          )}
                        </Cell>
                      </Column>

                    </Table>
                    </div>
                  </div>
                </div>
            </div>
          </Container>

          <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Drawer.Header>
            <Drawer.Title>Initialize Smart Contract</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => setDrawerOpen(false)}>Cancel</Button>
              <Button onClick={() => InitContract()} appearance="primary">
                Confirm
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
            <Drawer.Body>
              <h5>Genesis Roles</h5>
              <ThemeProvider theme={darktheme}>

                <JsonForms
                  //schema={testSchema}
                  schema={rolesSchema}
                  //uischema={uischema}
                  //data={rolesData}
                  renderers={materialRenderers}
                  cells={materialCells}
                  onChange={({ data, errors }) => setRolesData(data)}
                />

              </ThemeProvider>

              <br/><br/>
              <h5>Genesis Data</h5>
              <ThemeProvider theme={darktheme}>

                <JsonForms
                  schema={dataSchema}
                  // schema={testSchema}
                  //uischema={uischema}
                  //data={initData}
                  renderers={materialRenderers}
                  cells={materialCells}
                  onChange={({ data, errors }) => setInitData(data)}
                />

              </ThemeProvider>

            </Drawer.Body>
          </Drawer>

          <MessageModal />

          <Drawer open={hubDrawerOpen} onClose={() => setHubDrawerOpen(false)}>
          <Drawer.Header>
            <Drawer.Title>Add New Contracts Host</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => setHubDrawerOpen(false)}>Cancel</Button>
              <Button onClick={() => addContractHub()} appearance="primary">
                Confirm
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
            <Drawer.Body>
              <h5>Contract Host Details</h5>
              <ThemeProvider theme={darktheme}>

                <JsonForms
                  schema={{
                    type:"object",
                    properties:{
                      name:{type:"string"},
                      address:{type:"string"},
                    },
                    required:['name', 'address'],
                    additionalProperties:false
                  }}
                  data={newHubData}
                  renderers={materialRenderers}
                  cells={materialCells}
                  onChange={({ data, errors }) => setNewHubData(data)}
                />

              </ThemeProvider>
            </Drawer.Body>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default Contracthubs;
