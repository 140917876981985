import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styleguide.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "rsuite/dist/rsuite.min.css"
import { CustomProvider } from 'rsuite';


const contentStyle = {
  margin:20
}

const headerStyle = {
  marginLeft:20
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename=''>
    <CustomProvider theme="dark">
     <App />
    </CustomProvider>
  </BrowserRouter>
);

