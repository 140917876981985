import '../../App.css';
import './style.css';
import 'rsuite/dist/rsuite.min.css'
import {Button, Input, InputGroup } from 'rsuite';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { Checkboxes } from "../../components";
import { ChevronDown, EyeOff, Globe01, Lock01, UserCircle } from "../../icons";

const Login = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [viewPassword, setViewPassword] = React.useState('password');
  const [rememberMe, setRememberMe] = React.useState('selected');

  async function login(){
    console.log(window.location.origin+`/api/login`, username, password);
    const response = await fetch(window.location.origin+`/api/login`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa(username + ":" + password)},
    })
   
    let result =  await response.json();
    if (result.status == "success"){
      localStorage.setItem('basicAuth', 'Basic ' + btoa(username + ":" + password));
      localStorage.setItem("rlnparticipant", result.rlnparticipant);
      console.log("navigate to smartcontracts");
      navigate("/smartcontracts");
    } else {
      localStorage.removeItem('basicAuth');
    }
    console.log(result)

  } 

  return (
    <div className="login">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="BG">
            <div className="overlap-group">
              <div className="pattern">
                <div className="div">
                  <div className="pattern-2">
                    <img className="line" alt="Line" src="/line-556.svg" />
                    <div className="overlap-2">
                      <img className="img" alt="Line" src="/line-557.svg" />
                      <img className="line-2" alt="Line" src="/line-562.svg" />
                      <img className="line-3" alt="Line" src="/line-560.svg" />
                    </div>
                    <div className="overlap-group-2">
                      <img className="line-4" alt="Line" src="/line-563.svg" />
                      <img className="line-5" alt="Line" src="/line-565.svg" />
                      <img className="line-6" alt="Line" src="/line-564.svg" />
                    </div>
                  </div>
                  <img className="pattern-3" alt="Pattern" src="/pattern.png" />
                </div>
              </div>
              <div className="rectangle" />
            </div>
          </div>
          <div className="gradient" />
          <div className="content">
            <div className="frame">
              <div className="title">
                <div className="text-wrapper">Welcome back</div>
              </div>
              <div className="title-2">
                <img className="white-logo" alt="White logo" src="/white-logo-transparent-background-no-words.png" />
                <p className="p">Smart Contract Wallet</p>
              </div>
            </div>
            <div className="content-2">
              <div className="form">
                <img className="dot" alt="Dot" src="/dot.svg" />
                <div className="frame-2">
                  <InputGroup className="inputGroup">
                    <InputGroup.Addon className="inputField"><UserCircle className="icon-instance-node" /></InputGroup.Addon>
                    <Input 
                      className="inputField" 
                      placeholder="Username" 
                      type="text" 
                      onChange={e => setUsername(e)} 
                      value={username}
                    />
                  </InputGroup>
                  <div className="frame-4">
                    <div className="frame-5">
                      <a className="text-wrapper-2 cursorPointer">Forgot Password?</a>
                    </div>
                    <InputGroup className="inputGroup">
                      <InputGroup.Addon className="inputField"><Lock01 className="icon-instance-node" /></InputGroup.Addon>
                      <Input 
                        className="inputField" 
                        type={viewPassword} 
                        placeholder="Password" 
                        onChange={e => setPassword(e)} 
                        value={password} 
                        onKeyUp={e => (e.keyCode === 13 ? login(): null)}
                      />
                      <InputGroup.Addon className="inputField cursorPointer" onClick={()=>{setViewPassword(viewPassword == "password" ? "text" : "password")}}><EyeOff className="icon-instance-node" /></InputGroup.Addon>
                    </InputGroup>
                  </div>
                  <div className="frame-6">
                    <Checkboxes
                      className="checkboxes-instance cursorPointer"
                      containerClassName="design-component-instance-node"
                      type={rememberMe}
                    />
                    <div className="text-wrapper-3 cursorPointer">Remember Me</div>
                    {/* <Checkbox
                      checked={rememberMe}
                      onClick={() => {test()}}
                    >
                      <div className='cursorPointer' onClick={() => {test()}}>Remember Me</div>
                    </Checkbox> */}
                  </div>
                  <Button className="loginButton" onClick={()=>{login();}}>Login</Button>
                </div>
              </div>
              <div className="foot">
                <div className="frame-7">
                  <a className="text-wrapper-2 cursorPointer">Terms</a>
                  <div className="ellipse" />
                  <a className="text-wrapper-2 cursorPointer">Privacy</a>
                  <div className="ellipse" />
                  <a className="text-wrapper-2 cursorPointer">Docs</a>
                  <div className="ellipse" />
                  <a className="text-wrapper-2 cursorPointer">Help</a>
                </div>
                <div className="frame-8">
                  <Globe01 className="icon-instance-node" />
                  <div className="text-wrapper-2">English</div>
                  <ChevronDown className="icon-instance-node" />
                </div>
              </div>
            </div>
          </div>
          {/* <img className="bee" alt="Smart Contract" src="/smart-contract_9438073.png" /> */}
        </div>
      </div>
    </div>
  );
};

export default Login;