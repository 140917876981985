import '../../App.css';
import './style.css';
import { Button } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import { useNavigate } from "react-router-dom";
import MenuOptions from '../../components/navbar';
import GlobalStyle from '../../globalStyles';
import {Container, Header, Sidebar, Sidenav, Content, IconButton, Panel, FlexboxGrid, Drawer, Notification, useToaster} from 'rsuite';
import CheckRound  from '@rsuite/icons/CheckRound';
import WarningRound from '@rsuite/icons/WarningRound';
import PauseIcon from '@rsuite/icons/legacy/Pause';
import ReactJson from 'react-json-view';
import { createTheme } from '@mui/material/styles';
import { AuthedNavbar } from '../../components';
import React, { useState, useEffect } from 'react';
import { Table } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

let baseURL = window.location.origin;

const Confirmations = (props) => {
  const [active, setActive] = React.useState('Confirmations');
  const [open, setOpen] = React.useState(false);
  const [confirmations, setConfirmations] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState({})
  const [modalHeading, setModalHeading] = React.useState('Message')
  const [expand, setExpand] = React.useState(true);
  const [rlnAction, setRlnAction] = React.useState('');
  const [rlnJSON, setRlnJSON] = React.useState({});
  const [rlnTransaction, setRlnTransaction] = React.useState({});
  const [rlnMatched, setRlnMatched] = React.useState([]);
  const [currentFunction, setCurrentFunction] = React.useState();
  const [currentContractName, setCurrentContractName] = React.useState();
  const [currentContractHub, setCurrentContractHub] = React.useState();
  const [currentContractId, setCurrentContractId] = React.useState();
  const [currentParams, setCurrentParams] = React.useState({})

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let result =  getconfirmations();
  },[]);

  const toaster = useToaster();

  const successMessage = (messageText) => (
    <Notification type="success" header="Contract State Updated" closable style={{ width: 600 }}>
      <span style={{ width: 500 }}>
      Contract Id : <br />
      {messageText.contractId}<br />
      Hash : < br />
      {messageText.hash}
      </span>
      <hr />
    </Notification>
  );

  const failedMessage = (messageText) => (
    <Notification type="error" header="Function Failed" closable style={{ width: 600 }}>
      <span style={{ width: 500 }}>
      {messageText}
      </span>
      <hr />
    </Notification>
  );

  const Heading = ({selection}) => {
    return (
      <h4 style={GlobalStyle.pageTitleStyle}>{selection}</h4>
    )
  }  

  const logout = () => {
    localStorage.removeItem('basicAuth');
    window.location.href = window.location.origin + process.env.PUBLIC_URL;
  }

  async function getconfirmations(){
    console.log(baseURL+`/api/getconfirmations`)
    const response = await fetch(baseURL+`/api/getconfirmations`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    updateConfirmations(results.confirmations)
    console.log(results)
    return results;
  }

  async function getcontractdetails(contractHub, contractId){
    console.log(baseURL+`/api/getcontractdetails?contractHub=`+contractHub+`&contractId=`+contractId)
    const response = await fetch(baseURL+`/api/getcontractdetails?contractHub=`+contractHub+`&contractId=`+contractId, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    return results;
  }

  async function updateConfirmations(confirmationsList){
    let confirmationsArray = [];
    for (var i=0; i<confirmationsList.length; i++){
      let confirmationsObject = {};
      let atomicCount=confirmationsList[i].atomicGroup.length;
      for (var j=0; j<confirmationsList[i].atomicGroup.length; j++){
        confirmationsObject = confirmationsList[i].atomicGroup[j];
        if (j==0){
          console.log('[j]==0')
          confirmationsObject.actionRowSpan = atomicCount;
        }
        confirmationsObject.contractHub = confirmationsList[i].contractHub;
        confirmationsObject.contractId = confirmationsList[i].contractId;
        confirmationsObject.fromAgent = confirmationsList[i].atomicGroup[j].fromParticipant;
        confirmationsObject.toAgent = confirmationsList[i].atomicGroup[j].toParticipant;
        confirmationsObject.currency = confirmationsList[i].assetId;
        confirmationsObject.reference = confirmationsList[i].reference;
        confirmationsObject.status = confirmationsList[i].status;
        console.log(JSON.parse(JSON.stringify(confirmationsObject)))
        confirmationsArray.push(JSON.parse(JSON.stringify(confirmationsObject)));
      }
    }
    console.log(confirmationsArray)
    setConfirmations(confirmationsArray)
  }

  async function confirmAction(rowData){
    let thisRowData = rowData.rowData;
    console.log(rowData)
    let id = thisRowData.contractId+':'+thisRowData.reference;
    id = "sc:"+id.slice(-27);
    let from =localStorage.getItem('rlnparticipant');
    let groups = [];
    let transactions = [];

    for (var i=0; i<confirmations.length; i++){
      
      if (confirmations[i].contractId == thisRowData.contractId && confirmations[i].reference == thisRowData.reference){
        let transaction = {};
        transaction.amount = confirmations[i].amount;
        transaction.assetId = confirmations[i].assetId;
        transaction.toAccount = confirmations[i].toAccount;
        transaction.toAgent = confirmations[i].toParticipant;
        transaction.toIsAddress = false;
        transaction.fromAccount = confirmations[i].fromAccount;
        transaction.fromAgent = confirmations[i].fromParticipant;
        transaction.fromIsAddress = false;
        transactions.push(JSON.parse(JSON.stringify(transaction)));
      }
    }

    let group = {};
    group.transactions =[];
    group.transactions = transactions;
    groups.push(group);

    let rlncreate ={
      "id": id,
      "from": from,
      "groups": groups
    }

    let rlnTransactions = await getRLNTransactions(id)
    let transactionCheck =[];
    for (var i=0; i<transactions.length; i++){
        
        let checkObject = {
            "transaction":i,
            "part": "creditor",
            "found": false,
            "amount": false,
            "assetId": false,
            "success": false
        };
        // check creditor transaction
        for (var j=0; j<rlnTransactions.length; j++){
            if (rlnTransactions[j].address === transactions[i].toAccount &&
                rlnTransactions[j].partyCode === transactions[i].toAgent &&
                rlnTransactions[j].balance >0){
                checkObject.found = true;
                if (rlnTransactions[j].balance === transactions[i].amount){
                    checkObject.amount = true;
                }
                if (rlnTransactions[j].assetCode === transactions[i].assetId){
                    checkObject.assetId = true;
                }
                if (rlnTransactions[j].status === "SUCCESS"){
                    checkObject.success = true;
                }
            }
        }
        transactionCheck.push(JSON.parse(JSON.stringify(checkObject)));
        checkObject = {
            "transaction":i,
            "part": "debtor",
            "found": false,
            "amount": false,
            "assetId": false,
            "success": false
        };
        // check debtor transaction
        for (var j=0; j<rlnTransactions.length; j++){
            if (rlnTransactions[j].address === transactions[i].fromAccount &&
                rlnTransactions[j].partyCode === transactions[i].fromAgent &&
                rlnTransactions[j].balance < 0){
                checkObject.found = true;
                if ((rlnTransactions[j].balance*-1) === transactions[i].amount){
                    checkObject.amount = true;
                }
                if (rlnTransactions[j].assetCode === transactions[i].assetId){
                    checkObject.assetId = true;
                }
                if (rlnTransactions[j].status === "SUCCESS"){
                    checkObject.success = true;
                }
            }
        }
        transactionCheck.push(JSON.parse(JSON.stringify(checkObject)));
    }

    setRlnTransaction(rlnTransactions);
    setRlnMatched(transactionCheck);
    setRlnAction('Alleged RLN Transaction');
    setRlnJSON(rlncreate);

    let result = await getcontractdetails(thisRowData.contractHub, thisRowData.contractId);
    console.log("[contractDetails]",result)

    setCurrentContractHub(thisRowData.contractHub);
    setCurrentContractId(thisRowData.contractId);
    setCurrentContractName(result.contractDetails.contractName);
    setCurrentFunction("confirmPayment");
    setCurrentParams({paymentReference: thisRowData.reference});

    setDrawerOpen(true);
  }

  function Action(rowData){
    if (rowData.rowData.status == 'actioned'){
      return (
        <span>
          <a onClick={() => confirmAction(rowData)}> Confirm </a>
        </span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  function SetIcon(result) {
    if (result.result){
      return (
        <CheckRound style={{color: 'green'}}/>
      )
    } else {
      return (
        <WarningRound style={{color: 'red'}}/>
      )
    }
  }

  //const confirm
  
  const callFunction = async () => {
    try {
      let params = {};
      console.log("[currentContractName]",currentContractName)
      params.contractName=currentContractName;
      params.contractHub=currentContractHub;
      params.contractId=currentContractId;
      params.functionName=currentFunction;
      params.params=currentParams;
      let result = await contractFunction(params);
      if (result.hasOwnProperty('failed')) {
        if (result.failed.hasOwnProperty('error')) {
          toaster.push(failedMessage(result.failed.error), "topCenter");
        } else {
          toaster.push(failedMessage(result.failed), "topCenter");
        }
        return;
      } else {
        toaster.push(successMessage(result), "topCenter");
        getconfirmations();
        setDrawerOpen(false);
      }
    } catch (error) {
        console.log(error);
        toaster.push(failedMessage(error), "topCenter");
    }
    
  }

  async function contractFunction(params){
    const response = await fetch(baseURL+`/api/callfunction`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify(params)
    })
    let result = await response.json();
    console.log(result);
    return result;
  }

  async function getRLNTransactions(groupId){
    const response = await fetch(baseURL+`/api/rln/grouptransactions?groupId=`+groupId, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let result = await response.json();
    console.log(result);
    return result;
  }

  const CompactCell = (props) => {
    return <Cell {...props} style={{ padding: 5 , paddingTop: 10}} />;
  };

  return (
    <div className="confirmations">
      <div className="overlap-wrapper">
        <div className="overlap">
      
        <Container>
            <AuthedNavbar active={active} expand={expand} onSelect={setActive} expandCallBack={() => setExpand(!expand)} logoutCallBack={()=> logout()}/>
            <div className="frame-3">
              <div className="frame-5">
                <div className="frame-6">
                  <div className="transactions-table">
                    <div className="frame-18">
                      <div className="text-wrapper-7">Confirmations</div>
                    </div>

                    <Table virtualized
                    height={500}
                    width={'100%'}
                    hover={false}
                    data={confirmations} 
                    >
                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Host</HeaderCell>
                        <CompactCell dataKey="contractHub"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Id</HeaderCell>
                        <CompactCell dataKey="contractId"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Reference</HeaderCell>
                        <CompactCell dataKey="reference"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Status</HeaderCell>
                        <CompactCell dataKey="status"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Action</HeaderCell>
                        <CompactCell dataKey="action"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>From Agent</HeaderCell>
                        <CompactCell dataKey="fromAgent"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>To Agent</HeaderCell>
                        <CompactCell dataKey="toAgent"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Currency</HeaderCell>
                        <CompactCell dataKey="assetId"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Amount</HeaderCell>
                        <CompactCell dataKey="amount"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Actions</HeaderCell>

                        <Cell style={{padding:5}}>
                          {rowData => (
                            <Action rowData = {rowData} />  
                          )}
                        </Cell>
                      </Column>

                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} enforceFocus={false}>
          <Drawer.Header>
            <Drawer.Title>Confirm Action</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => {setDrawerOpen(false)}}>Cancel</Button>
              <Button onClick={() => {callFunction()}} appearance="primary">
                Confirm
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
            <Drawer.Body enforceFocus={true}>
              <h5>{rlnAction}</h5><br/>
              <ReactJson src={rlnJSON} collapsed={2} theme={"monokai"} /><br/>
            </Drawer.Body>
          </Drawer>

        </div>
      </div>
    </div>
  );
};

export default Confirmations;