import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes, Router, Navigate} from 'react-router-dom';
import Smartcontracts from './pages/Smartcontracts/smartcontracts';
import Contracthubs from './pages/Contracthubs/contracthubs';
import Login from './pages/Login/login';
import Actions from './pages/Actions/actions';
import Confirmations from './pages/Confirmations/confirmations';
import getConfig from './configLoader';

function checkLoggedIn() {
    if (localStorage.getItem('basicAuth') === null) {
        console.log('logged out');
        return false;
    } else {
        console.log('logged in');
        return true;
    }
}

function App() {

    // let isLoggedIn = checkLoggedIn();
    const appConfig = getConfig();

    console.log("Config : " + JSON.stringify(appConfig));

    return (
        <div>

            <Routes>
                <Route exact path='/' element={<Login/>}/>
                {/* <Route exact path='/' element={<Confirmations
                            config= {{ ...appConfig }}
                            />}/> */}

                <Route
                    path="/contracthosts"
                    element={
                        <RequireAuth redirectTo="/">
                            <Contracthubs
                            config= {{ ...appConfig }}
                            />
                        </RequireAuth>}
                />

                <Route
                    path="/smartcontracts"
                    element={
                        <RequireAuth redirectTo="/">
                            <Smartcontracts/>
                        </RequireAuth>}
                />

                <Route
                    path="/actions"
                    element={
                        <RequireAuth redirectTo="/">
                            <Actions/>
                        </RequireAuth>}
                />

                <Route
                    path="/confirmations"
                    element={
                        <RequireAuth redirectTo="/">
                            <Confirmations/>
                        </RequireAuth>}
                />

                // take care of redirects for all paths that doesn't match the defined
                <Route path={"*"} element={<Navigate replace to={"/"}/>}/>
            </Routes>

        </div>
    );
}

function RequireAuth({children, redirectTo}) {
    let isAuthenticated = checkLoggedIn();
    return isAuthenticated ? children : <Navigate to={redirectTo}/>;
}

export default App;
