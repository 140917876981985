import '../../App.css';
import './style.css';
import { Button } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import { useNavigate } from "react-router-dom";
import MenuOptions from '../../components/navbar';
import GlobalStyle from '../../globalStyles';
import {Container, Header, Sidebar, Sidenav, Content, IconButton, Panel, FlexboxGrid, Drawer, Notification, useToaster} from 'rsuite';
import PauseIcon from '@rsuite/icons/legacy/Pause';
import ReactJson from 'react-json-view';
import React, { useState, useEffect } from 'react';
import { Table } from 'rsuite';
import { AuthedNavbar } from '../../components';
const { Column, HeaderCell, Cell } = Table;

let baseURL = window.location.origin;

const Actions = (props) => {
  const [active, setActive] = React.useState('Actions');
  const [actions, setActions] = React.useState([]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [expand, setExpand] = React.useState(true);
  const [rlnAction, setRlnAction] = React.useState('');
  const [rlnJSON, setRlnJSON] = React.useState({});
  const [currentFunction, setCurrentFunction] = React.useState();
  const [currentContractName, setCurrentContractName] = React.useState();
  const [currentContractHub, setCurrentContractHub] = React.useState();
  const [currentContractId, setCurrentContractId] = React.useState();
  const [currentParams, setCurrentParams] = React.useState({})
  const [drawerType, setDrawerType] = React.useState('')
  
  useEffect(() => {
    let result =  getactions();
  },[]);

  const toaster = useToaster();

  const successMessage = (messageText) => (
    <Notification type="success" header="Contract State Updated" closable style={{ width: 600 }}>
      <span style={{ width: 500 }}>
      Contract Id : <br />
      {messageText.contractId}<br />
      Hash : < br />
      {messageText.hash}
      </span>
      <hr />
    </Notification>
  );

  const failedMessage = (messageText) => (
    <Notification type="error" header="Function Failed" closable style={{ width: 600 }}>
      <span style={{ width: 500 }}>
      {messageText}
      </span>
      <hr />
    </Notification>
  );

  const Heading = ({selection}) => {
    return (
      <h4 style={GlobalStyle.pageTitleStyle}>{selection}</h4>
    )
  }  

  const logout = () => {
    localStorage.removeItem('basicAuth');
    window.location.href = window.location.origin + process.env.PUBLIC_URL;
  }

  async function getactions(){
    console.log(baseURL+`/api/getactions`)
    const response = await fetch(baseURL+`/api/getactions`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    console.log(results)
    updateActions(results.actions)
    return results;
  }

  async function getcontractdetails(contractHub, contractId){
    console.log(baseURL+`/api/getcontractdetails?contractHub=`+contractHub+`&contractId=`+contractId)
    const response = await fetch(baseURL+`/api/getcontractdetails?contractHub=`+contractHub+`&contractId=`+contractId, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    return results;
  }

  async function updateActions(actions){
    let actionsArray = [];

    for (var i=0; i<actions.length; i++){

      if (actions[i].status === 'proposed'){

        let actionsObject = {};

        // deal with RLN Create Action
        if (actions[i].type == "RLNCREATE"){
          let atomicCount=actions[i].atomicGroup.length;
          for (var j=0; j<actions[i].atomicGroup.length; j++){
            //console.log('[j]',j)
            actionsObject = actions[i].atomicGroup[j];
            if (j==0){
              //console.log('[j]==0')
              actionsObject.actionRowSpan = atomicCount;
            }
            actionsObject.contractHub = actions[i].contractHub;
            actionsObject.contractId = actions[i].contractId;
            actionsObject.fromAgent = actions[i].atomicGroup[j].fromParticipant;
            actionsObject.toAgent = actions[i].atomicGroup[j].toParticipant;
            actionsObject.assetId = actions[i].atomicGroup[j].assetId;
            actionsObject.reference = actions[i].reference;
            actionsObject.status = actions[i].status;
            console.log(JSON.parse(JSON.stringify(actionsObject)))
            actionsArray.push(JSON.parse(JSON.stringify(actionsObject)));
          }
        }

        // Deal with RLN Approve Action
        if (actions[i].type == "RLNAPPROVAL"){
          actionsObject = actions[i]
          actionsObject.contractHub = actions[i].contractHub;
          actionsObject.contractId = actions[i].contractId;
          actionsObject.reference = actions[i].id;
          actionsObject.status = actions[i].status;
          actionsObject.action = 'approve';
          console.log(JSON.parse(JSON.stringify(actionsObject)))
          actionsArray.push(JSON.parse(JSON.stringify(actionsObject)));
        }

        // Deal with SMARTCONTRACT Action
        if (actions[i].type == "SMARTCONTRACT"){
          actionsObject = actions[i]
          actionsObject.contractHub = actions[i].contractHub;
          actionsObject.contractId = actions[i].contractId;
          actionsObject.reference = actions[i].reference;
          actionsObject.status = actions[i].status;
          actionsObject.action = 'contract';
          console.log(JSON.parse(JSON.stringify(actionsObject)))
          actionsArray.push(JSON.parse(JSON.stringify(actionsObject)));
        }
      }
      
    }
    console.log(actionsArray)
    setActions(actionsArray)
  }

  async function executeAction(thisRowData){
    console.log(thisRowData)

    if (thisRowData.action == 'create'){
      let transactions = [];

      for (var i=0; i<actions.length; i++){
        if (actions[i].contractId == thisRowData.contractId && actions[i].reference == thisRowData.reference){
          let transaction = {
            "amount": actions[i].amount,
            "assetId": actions[i].assetId,
            "from": {
              "account": actions[i].fromAccount,
              "domain": actions[i].fromDomain, // location of account in ledger swarm network - natwest
              "participant": actions[i].fromParticipant // natwest ipswich
            },
            "to": {
              "account": actions[i].toAccount,
              "domain": actions[i].toDomain,
              "participant": actions[i].toParticipant
            },
            "transferId": crypto.randomUUID(),
          }
          transactions.push(transaction);
        }
      }

      let rlncreate = {
        "manifestId": crypto.randomUUID(),
        "transfers": transactions  
      }

      let result = await getcontractdetails(thisRowData.contractHub, thisRowData.contractId);
      console.log("[contractDetails]",result)

      console.log(result.contractDetails.contractName)

      setCurrentContractHub(thisRowData.contractHub);
      setCurrentContractId(thisRowData.contractId);
      setCurrentContractName(result.contractDetails.contractName);
      setCurrentFunction("confirmActioned");
      setCurrentParams({paymentReference: thisRowData.reference});

      setRlnAction('RLN CREATE Transaction');
      setRlnJSON(rlncreate);
      
    }

    if (thisRowData.action == 'approve'){

    }
  
    if (thisRowData.action == 'contract'){
      let result = await getcontractdetails(thisRowData.contractHub, thisRowData.contractId);

      setRlnAction('Create Smart Contracts');
      setRlnJSON(thisRowData.newContracts);

      setCurrentContractHub(thisRowData.contractHub);
      setCurrentContractId(thisRowData.contractId);
      setCurrentContractName(result.contractDetails.contractName);
      setCurrentFunction("confirmActioned");
      setCurrentParams({paymentReference: thisRowData.reference});
    }

    setDrawerType(thisRowData.action);   
    setDrawerOpen(true);
  }

  const callFunction = async () => {
    try {
      if (drawerType === 'create'){
        // check if transaction already exists
        
        let rlnCreate = await createRLNTransaction(rlnJSON);
        console.log(rlnCreate)

        if (rlnCreate.hasOwnProperty('manifestId')){
          let params = {};
          console.log("[currentContractName]",currentContractName)
          params.contractName=currentContractName;
          params.contractHub=currentContractHub;
          params.contractId=currentContractId;
          params.functionName=currentFunction;
          params.params=currentParams;
          let result = await contractFunction(params);
          if (result.hasOwnProperty('failed')) {
            if (result.failed.hasOwnProperty('error')) {
              toaster.push(failedMessage(result.failed.error), "topCenter");
            } else {
              toaster.push(failedMessage(result.failed), "topCenter");
            }
            return;
          } else {
            toaster.push(successMessage(result), "topCenter");
          }
        }else {
          toaster.push(failedMessage("Unable to execute RLN Transaction"), "topCenter");
        }
      }

      if (drawerType === 'contract'){
        // go create the contracts.
        await createContracts(rlnJSON);

        // then mark the contract as actioned.
        let params = {
          contractName: currentContractName,
          contractHub: currentContractHub,
          contractId: currentContractId,
          functionName: currentFunction,
          params: currentParams,
        }
        let result = await contractFunction(params);
          if (result.hasOwnProperty('failed')) {
            if (result.failed.hasOwnProperty('error')) {
              toaster.push(failedMessage(result.failed.error), "topCenter");
            } else {
              toaster.push(failedMessage(result.failed), "topCenter");
            }
            return;
          } else {
            toaster.push(successMessage(result), "topCenter");
          }
      }
    } catch (error) {
        console.log(error);
        toaster.push(failedMessage(error), "topCenter");
    }
    
    getactions()
    setDrawerOpen(false);
  }

  async function contractFunction(params){
    const response = await fetch(baseURL+`/api/callfunction`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify(params)
    })
    let result = await response.json();
    console.log(result);
    return result;
  }

  async function createRLNTransaction(params){
    const response = await fetch(baseURL+`/api/rln/create`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify(params)
    })
    let result = await response.json();
    console.log(result);
    return result;
  }

  async function createContracts(params){
    const response = await fetch(baseURL+`/api/newcontracts`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify(params)
    })
    let result = await response.json();
    console.log(result);
    return result;
  }

  const CompactCell = (props) => {
    return <Cell {...props} style={{ padding: 5 , paddingTop: 10}} />;
  };


  return (
    <div className="actions">
      <div className="overlap-wrapper">
        <div className="overlap">
          <Container>
            <AuthedNavbar active={active} expand={expand} onSelect={setActive} expandCallBack={() => setExpand(!expand)} logoutCallBack={()=> logout()}/>
            <div className="frame-3">
              <div className="frame-5">
                <div className="frame-6">
                  <div className="transactions-table">
                    <div className="frame-18">
                      <div className="text-wrapper-7">Actions</div>
                    </div>

                    <Table virtualized
                    height={500}
                    width={'100%'}
                    hover={false}
                    data={actions} 
                    >
                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Host</HeaderCell>
                        <CompactCell dataKey="contractHub"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Id</HeaderCell>
                        <CompactCell dataKey="contractId"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Reference</HeaderCell>
                        <CompactCell dataKey="reference"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Status</HeaderCell>
                        <CompactCell dataKey="status"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Action</HeaderCell>
                        <CompactCell dataKey="action"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>From Agent</HeaderCell>
                        <CompactCell dataKey="fromAgent"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>To Agent</HeaderCell>
                        <CompactCell dataKey="toAgent"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Currency</HeaderCell>
                        <CompactCell dataKey="assetId"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Amount</HeaderCell>
                        <CompactCell dataKey="amount"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Actions</HeaderCell>

                        <Cell style={{padding:5}}>
                          {rowData => (
                            <span>
                              <a onClick={() => executeAction(rowData)}> Execute </a>
                            </span>
                          )}
                        </Cell>
                      </Column>

                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Container>
      
          <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Drawer.Header>
            <Drawer.Title>Execute Action</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => {setDrawerOpen(false)}}>Cancel</Button>
              <Button onClick={() => {callFunction()}} appearance="primary">
                Confirm
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
            <Drawer.Body>
              <h5>{rlnAction}</h5><br/>
                <ReactJson src={rlnJSON} collapsed={5} enableClipboard={true} theme={"monokai"}/>
            </Drawer.Body>
          </Drawer>

        </div>
      </div>
    </div>
  );
};

export default Actions;