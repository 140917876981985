import React, { useEffect } from 'react';
import 'rsuite/dist/rsuite.min.css'
import { Sidebar, Sidenav, Nav } from 'rsuite';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import PlayOutline from '@rsuite/icons/PlayOutline';
import Conversion from '@rsuite/icons/Conversion';
import ShareOutline from '@rsuite/icons/ShareOutline';
import ChangeListIcon from '@rsuite/icons/ChangeList';
import OffIcon from '@rsuite/icons/Off';
// import { CategoryExit1 } from "../../icons/CategoryExit1";
import { useNavigate } from 'react-router-dom';
import './style.css';

export const AuthedNavbar = ({ active, expand, onSelect, expandCallBack, logoutCallBack, ...props }) => {
    const navigate = useNavigate();
    const [buttonMargin, setButtonMargin] = React.useState(200);

    let autoResize = () => {
        setButtonMargin(window.innerHeight - 487);
    }

    useEffect(() => {
        window.addEventListener('resize', autoResize)
        autoResize();
    }, [])

    return (
        <Sidebar
            width={expand ? 300 : 75}
            className="sideBar"
            collapsible
            expanded={expand}
            >
            <Sidenav.Header>
                <img className={expand ? "sidebar logoLarge" : "sidebar logoSmall"} src={expand ? "/logo-1.png": "/white-logo-transparent-background-no-words.png"}/>
            </Sidenav.Header>
            <Sidenav expanded={expand} defaultOpenKeys={['3']} appearance="subtle">
                <Sidenav.Body>
                    {!expand && <div className="btn-navbar-small" onClick={expandCallBack}>&gt;</div>}
                    {expand && <div className="btn-navbar-large" onClick={expandCallBack}>&lt;</div>}
                    <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 50 }}>
                        <Nav.Item eventKey="Contract Hosts" icon={<ChangeListIcon />} onSelect={() => navigate("/contracthosts")}>
                            Contract Hosts
                        </Nav.Item>
                        <Nav.Item eventKey="Smart Contracts" icon={<SingleSourceIcon />} onSelect={() => navigate("/smartcontracts")}>
                            Smart Contracts
                        </Nav.Item>
                        <Nav.Item eventKey="Actions" icon={<PlayOutline />} onSelect={() => navigate("/actions")}>
                            Actions
                        </Nav.Item>
                        <Nav.Item eventKey="Confirmations" icon={<Conversion />} onSelect={() => navigate("/confirmations")}>
                            Confirmations
                        </Nav.Item>
                        {/* <Nav.Item eventKey="triggers" icon={<ShareOutline />} onSelect={() => navigate("/triggers")}>
                            Triggers
                        </Nav.Item> */}
                    </Nav>

                    <Nav {...props} onSelect={onSelect} style={{ marginTop: buttonMargin }}>
                        <Nav.Item eventKey="logout" icon={<OffIcon />} onSelect={logoutCallBack}>
                            Log out
                        </Nav.Item>
                    </Nav>
                </Sidenav.Body>
            </Sidenav>
        </Sidebar>
    );
  };