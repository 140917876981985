import '../../App.css';
import './style.css';
import { Button } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import { useNavigate } from "react-router-dom";
import GlobalStyle from '../../globalStyles';
import {Modal, Container, Drawer, Notification, useToaster} from 'rsuite';
import ReactJson from 'react-json-view';
import {materialRenderers,materialCells,} from '@jsonforms/material-renderers';
import { JsonForms } from '@jsonforms/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthedNavbar } from '../../components';
import React, { useState, useEffect } from 'react';


import { Table } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

const darktheme = createTheme({
  palette: {
      type: 'dark', mode: 'dark'
  }
});

let baseURL = window.location.origin;

// function to convert a base64 string into a json object
function decodeObject(objectString) {
  return JSON.parse(Buffer.from(objectString, 'base64').toString('ascii'));
}

const Smartcontracts = (props) => {

  const [active, setActive] = React.useState('Smart Contracts');
  const [open, setOpen] = React.useState(false);
  const [contractList, setContractList] = React.useState([])
  const [overflow, setOverflow] = React.useState(true);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [availableContracts, setAvailableContracts] = React.useState([])
  const [selectedContract, setSelectedContract] = React.useState(0)
  const [modalMessage, setModalMessage] = React.useState({})
  const [modalHeading, setModalHeading] = React.useState('Message')
  const [expand, setExpand] = React.useState(true);

  const [functionInput, setFunctionInput] = React.useState({})
  const [functionDropdown, setFunctionDropdown] = React.useState({})
  const [currentFunction, setCurrentFunction] = React.useState();
  const [currentContractHub, setCurrentContractHub] = React.useState();
  const [currentContractName, setCurrentContractName] = React.useState();
  const [currentContractId, setCurrentContractId] = React.useState();
  const [currentParams, setCurrentParams] = React.useState({})
  const [showCompletedContracts, setShowCompletedContracts] = React.useState(false)
  const [showActions, setShowActions] = React.useState(true)

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toaster = useToaster();

  const successMessage = (messageText) => (
    <Notification type="success" header="Contract State Updated" closable style={{ width: 600 }}>
      <span style={{ width: 500 }}>
      Contract Id : <br />
      {messageText.contractId}<br />
      Hash : < br />
      {messageText.hash}
      </span>
      <hr />
    </Notification>
  );

  const failedMessage = (messageText) => (
    <Notification type="error" header="Function Failed" closable style={{ width: 600 }}>
      <span style={{ width: 500 }}>
      {messageText}
      </span>
      <hr />
    </Notification>
  );

  const Heading = ({selection}) => {
    return (
      <h4 style={GlobalStyle.pageTitleStyle}>{selection}</h4>
    )
  }

  const logout = () => {
    localStorage.removeItem('basicAuth');
    window.location.href = window.location.origin + process.env.PUBLIC_URL;
  }

  async function updateContractList(contracts = {}){
    let allContracts = [];
    Object.keys(contracts).forEach((key) => {
      allContracts.push(contracts[key].map((value) => {
        return [key, value]
      }))
    })
    allContracts = allContracts.flat()

    let newContractList = await Promise.all(allContracts.map(async (value) => {
      return await getcontractdetails(value[0], value[1]).then((result) => {
        return {
          contractHub: value[0],
          contractId: value[1],
          contractName: result.contractDetails.contractName,
          description: result.contractDetails.description,
          comment: result.contractDetails.comment,
          status: result.contractDetails.status
        }
      })
    }))

    setContractList(newContractList)
  }

  async function getcontractdetails(contractHub, contractId){

    const response = await fetch(baseURL+`/api/getcontractdetails?contractHub=`+contractHub+`&contractId=`+contractId, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    console.log('[results getcontractdetails]',results);
    return results;

  }

  async function getcontract(contractHub, contractId){
    const response = await fetch(baseURL+`/api/getcontract?contractId=`+contractId+`&contractHub=`+contractHub, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    console.log('[results getcontract]',results);
    return results;
  }

  async function getStateObject(contractHub,hash){
    const response = await fetch(baseURL+`/api/getstateobject?hash=`+hash+"&contractHub="+contractHub, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    console.log('[results stateObject]',results);
    return results;
  }

  async function getroles(roles, state){
    const response = await fetch(baseURL+`/api/getroles?roles=`+roles+`&state=`+state, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    console.log('getRoles', results)
    return results;
  }

  async function getcontractlist(){
    const response = await fetch(baseURL+`/api/getcontractlist`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    console.log('getcontractlist', results)
    updateContractList(results.contractList)
    return results;
  }

  async function getavailablecontracts(){
    const response = await fetch(baseURL+`/api/getavailablecontracts`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
    })
    let results = await response.json();
    console.log(results.availableContracts)
    setAvailableContracts(results.availableContracts)
    return results;
  }

  useEffect(() => {
    getcontractlist();
    getavailablecontracts();
  },[]);

  const ShowDrawer = async (contractHub, contractName, contractId, MessageHeading) => {
    let thisContract=0;
    console.log('[MessageHeading]',MessageHeading)
    setCurrentContractHub(contractHub);
    setCurrentContractName(contractName);
    setCurrentContractId(contractId);
    for (var i=0; i<availableContracts.length; i++) {
      if (availableContracts[i].contractName == contractName) {
          setSelectedContract(i)
          thisContract=i;
      }
    }

    // // need to return a list of functions for this user's roles, for this contract, that can happen next.
    // let nextFunctions = [];
    // let result = await getcontract(contractHub, contractId);

    // var decodedString = atob(result[0]);
    // console.log('[decodedString]',decodedString)
    // var parsedString = JSON.parse(decodedString);
    // console.log('[parsedString]',parsedString)
    // //var status = res.state.status;

    // //extract the caller roles
    // var roles = await getroles(parsedString.stateRoles, parsedString.stateData);
    // var callerRoles = [];
    // for(var role in roles){
    //   if (roles[role].party == res.caller){
    //     callerRoles.push(role)
    //   }
    // }
    
    // var constraints = availableContracts[thisContract].flowConstraints;
  
    // nextFunctions = [...new Set(nextFunctions)];

    let functionsList=[]
    for (var fkey in availableContracts[thisContract]['functions']) {
      //if (fkey!='init' && nextFunctions.includes(fkey)){
      if (fkey!='init'){
        console.log('[found function]', fkey)
        functionsList.push(fkey)
      }
    }

    setShowActions(functionsList.length > 0)

    let functionsSchema={};
    functionsSchema.type="object";
    functionsSchema.properties = {};
    functionsSchema.properties.function={type:"string", enum:functionsList}
    functionsSchema.required=["function"]

    setFunctionDropdown(functionsSchema);

    handleDrawerOpen();
  }

  const ShowMessage = async (contractHub, Message, MessageHeading) => {
    //console.log('[MessageHeading]',MessageHeading)
    setModalMessage('result');
    setModalHeading(MessageHeading +' for '+Message);
    let modalMessageArray = [];
    let result = await getcontract(contractHub, Message);
    console.log('[getcontract]',result)

    for (var i = 0; i < result.length; i++) {
      var decodedString = atob(result[i]);
      console.log(decodedString,i)
      modalMessageArray.push(JSON.parse(decodedString));

      var transformHash = JSON.parse(decodedString).transforms;
      console.log(transformHash)
      let stateObjectb64 = await getStateObject(contractHub, transformHash);
      let stateObject = atob(stateObjectb64.object);
      console.log(stateObject)
      modalMessageArray.push((JSON.parse(stateObject)));

      var dataHash = JSON.parse(decodedString).stateData;
      console.log(dataHash)
      stateObjectb64 = await getStateObject(contractHub, dataHash);
      stateObject = atob(stateObjectb64.object);
      console.log(stateObject)
      modalMessageArray.push(JSON.parse(stateObject));

      var rolesHash = JSON.parse(decodedString).stateRoles;
      console.log(rolesHash)
      stateObjectb64 = await getStateObject(contractHub, rolesHash);
      stateObject = atob(stateObjectb64.object);
      console.log(stateObject)
      modalMessageArray.push(JSON.parse(stateObject));

    }

    setModalMessage(modalMessageArray)
    handleOpen();
  }

  const MessageModal = () => {

    let stateobject = {"stateObject":(modalMessage[0])}
    let transforms = {"transforms":modalMessage[1]}
    let stateData = {"stateData":(modalMessage[2])}
    let priorState = {"priorState":(modalMessage[3])}

    return (
      <Modal size={'lg'} overflow={overflow} open={open} onClose={handleClose} enforceFocus={false}>
        <Modal.Header>
            <Modal.Title>{modalHeading}</Modal.Title>
        </Modal.Header>
      <Modal.Body>
        <h5>Current State Object</h5>
        <ReactJson src={modalMessage[0]} collapsed={2} theme={"monokai"} />
        <h5>Transforms</h5>
        <ReactJson src={modalMessage[1]} collapsed={2} theme={"monokai"} />
        <h5>Current State Data</h5>
        <ReactJson src={modalMessage[2]} collapsed={2} theme={"monokai"} />
        <h5>Current State Roles</h5>
        <ReactJson src={modalMessage[3]} collapsed={2} theme={"monokai"} />
        <h5>Prior State Object</h5>
        <ReactJson src={modalMessage[4]} collapsed={2} theme={"monokai"} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="primary">
          Ok
        </Button>
        <Button onClick={handleClose} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
    )

  }

  const switchInput = (data) => {
    if(data){
      console.log(data)
      let inputSchema={}
      if (availableContracts[selectedContract]['schemas'].hasOwnProperty(data.function)){
        inputSchema=availableContracts[selectedContract]['schemas'][data.function]
      }
      setFunctionInput(inputSchema);
      setCurrentFunction(data.function);
    }
  }

  const callFunction = async () => {
    try {
      let params = {};
      params.contractHub=currentContractHub;
      params.contractName=currentContractName;
      params.contractId=currentContractId;
      params.functionName=currentFunction;
      params.params=currentParams;
      let result = await contractFunction(params);
      
      if (result.hasOwnProperty('failed')) {
        if (result.failed.hasOwnProperty('error')) {
          toaster.push(failedMessage(result.failed.error), "topCenter");
        } else {
          console.log('trying to show failed message')
          if (typeof result.failed === 'string') {
            toaster.push(failedMessage(result.failed), "topCenter");
          } else {
            toaster.push(failedMessage('Error'), "topCenter");
          }
        }
        return;
      } else {
        toaster.push(successMessage(result), "topCenter");
        setDrawerOpen(false)
      }
    } catch (error) {
        console.log(error);
        toaster.push(failedMessage(error), "topCenter");
    }

  }

  async function contractFunction(params){
    const response = await fetch(baseURL+`/api/callfunction`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': localStorage.getItem('basicAuth')},
      body: JSON.stringify(params)
    })
    let result = await response.json();
    console.log(result);
    return result;
  }

  const CompactCell = (props) => {
    return <Cell {...props} style={{ padding: 5 , paddingTop: 10}} />;
  };

  return (
    <div className="smartContracts">
      <div className="overlap-wrapper">
        <div className="overlap">
          <Container>
            <AuthedNavbar active={active} expand={expand} onSelect={setActive} expandCallBack={() => setExpand(!expand)} logoutCallBack={()=> logout()}/>
            <div className="frame-3">
              <div className="frame-5">
                <div className="frame-6">
                  <div className="transactions-table">
                    <div className="frame-18">
                      <div className="text-wrapper-7">Smart Contracts</div>
                      <div className="frame-22">
                        <input type="checkbox" checked={showCompletedContracts} onChange={() => setShowCompletedContracts(!showCompletedContracts)} /> <span onClick={() => setShowCompletedContracts(!showCompletedContracts)}>Show Completed Contracts?</span>
                      </div>
                    </div>

                    <Table virtualized
                    height={500}
                    width={'100%'}
                    hover={false}
                    data={contractList.filter((contract) => { return contract.status !== 'complete' || showCompletedContracts })} 
                    >

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Host</HeaderCell>
                        <CompactCell dataKey="contractHub"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Id</HeaderCell>
                        <CompactCell dataKey="contractId"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Contract Name</HeaderCell>
                        <CompactCell dataKey="contractName"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Comment</HeaderCell>
                        <CompactCell dataKey="comment"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Status</HeaderCell>
                        <CompactCell dataKey="status"  style={{padding:5}}/>
                      </Column>

                      <Column
                        flexGrow={1}
                        fullText>
                        <HeaderCell>Actions</HeaderCell>

                        <Cell style={{padding:5}}>
                          {rowData => (
                            <span>
                              <a onClick={() => ShowMessage(rowData.contractHub, rowData.contractId,'Smart Contract State')}> View </a>
                            </span>
                          )}
                        </Cell>
                      </Column>

                      <Column 
                      flexGrow={1} 
                      fullText>
                        <HeaderCell>...</HeaderCell>
                        <Cell style={{padding:5}}>
                          {rowData => (
                            <span>
                              {rowData.status !== 'complete' && <a onClick={() => ShowDrawer(rowData.contractHub, rowData.contractName, rowData.contractId, 'Smart Contract Functions')}> Call Function </a>}
                            </span>
                          )}
                        </Cell>
                      </Column>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Container>

          <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Drawer.Header>
            <Drawer.Title>Call Smart Contract Function</Drawer.Title>
            <Drawer.Actions>
              <Button onClick={() => {setDrawerOpen(false); setFunctionInput({})}}>Cancel</Button>
              <Button onClick={() => callFunction()} appearance="primary">
                Confirm
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
            <Drawer.Body>
              <h5>Smart Contract Function</h5>
              {showActions &&
                <>
                  <ThemeProvider theme={darktheme}>
                      <JsonForms
                        schema={functionDropdown}
                        //uischema={uischema}
                        //data={rolesData}
                        renderers={materialRenderers}
                        cells={materialCells}
                        onChange={({ data, errors }) => switchInput(data)}
                      />
                  </ThemeProvider>

                  <br/><br/>
                  <h5>Parameters</h5>
                  <ThemeProvider theme={darktheme}>
                    <JsonForms
                      schema={functionInput}
                      //uischema={uischema}
                      //data={rolesData}
                      renderers={materialRenderers}
                      cells={materialCells}
                      onChange={({ data, errors }) => setCurrentParams(data)}
                    />
                  </ThemeProvider>
                </>
              }
              {!showActions &&
                <div>No Actions available for the current smart contract.</div>
              }
            </Drawer.Body>
          </Drawer>

          <MessageModal />

        </div>
      </div>
    </div>
  );
};

export default Smartcontracts;
